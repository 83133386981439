<template>
  <div>
    Loading...
  </div>
</template>

<script>
import axios from 'axios'
export default {
  created() {
    var _self = this
    _self.qid = _self.$route.params.qid
    axios.get(process.env.VUE_APP_API_URL_HIS+'/api/getPatientInfoByQid/'+_self.qid).then((res) => {
      if(res.data.status=="OK"){
        _self.pid = res.data.data.pid
      }
    }).then(() => {
      _self.$store.commit('SET_CALLING',false)           // 通話狀態
      _self.$store.commit('SET_SYNC_STATUS',false)       // 醫療影像同步狀態
      if(this.partnerid == 6233) {
        // to crm form demo
        _self.$router.push('/housedemo')
      }else{
        _self.$router.push('/emr2/'+_self.pid+'/'+_self.qid)
      }
    }).catch((e) => {
      console.log("get pid api",e)
    })
  },
  data() {
    return {
      qid: 0,
      pid: 0
    }
  },
  computed: {
    partnerid() {
      return this.$store.getters.partner
    }
  }
}
</script>